.detailsInfoCard-container {
    width: 330px;
    height: 160px;
    color: #fff;
    border: 10px solid #000;
    /* 不设置border有可能显示不出边框图片 */
    /* 设置边框图片的各种属性 */
    border-image-source: url('../../assets/images/subPage/border.png');
    /* 边框图片的路径 */
    border-image-slice: 10;
    /* 边框图片的裁剪(裁剪出四个角),不需要单位 */
    border-image-width: 10px;
    border-image-repeat: round;
    /* 平铺方式 repeat:平铺可能显示不完整 round:平铺但会自动调整 stretch:拉伸 */
    box-sizing: border-box;

    .detailsInfoCard-title {
        font-size: 30px;
        margin-left: 10px;
    }

    .detailsInfoCard-content {
        font-size: 30px;
        font-weight: bold;
        margin-left: 90px;
            
        .detailsInfoCard-span {
            font-size: 85px;
            font-weight: bold;
            color: #05D380;
            margin-right: 15px;
        }

        .worring {
            color: #E81F1F;
        }
        
        .bad {
            color: #FC9800;
        }
    }

    .overRun {
        margin-left: 45px;
    }
}