.homeEnvironment-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    padding:0 20px;

    &::after{
        content: ''; // 必须
        position: absolute;
        left: 40px;
        top: 260px;
        right: 0;
        width: 330px;
        height: 1px;
        background-color: #dcdcdc;
        opacity: 0.5;   
    }

    &::before{
        content: ''; // 必须
        position: absolute;
        left: 200px;
        top: 100px;
        right: 0;
        width: 1px;
        height: 340px;
        background-color: #dcdcdc;
        opacity: 0.5;
    }

    .homeflipper-container {
        width: 50%;
        height: 190px;
        box-sizing: border-box;
        padding-left: 35px;
        padding-top: 40px;
        .homeflipper-title {
            font-size: 23px;
        }

        .homeflipper-unit {
            margin-left: 10px;
            font-size: 23px;
            font-weight: normal;
        }

        .homeflipper-data {
            font-size: 60px;
            margin-right: 5px;
        }
    }

    .overRun {
        padding-left: 0px;

        .homeflipper-title {
            padding-left: 35px;
        } 
    }
}