.liveVideo-container {
    width           : 526px;
    height          : 360px;
    margin-top      : 20px;
    // margin-left     : 40px;
    background-color: rgba(0, 0, 3, 0.17);
    color           : #fff;
    border: 5px solid #fff;
    box-sizing: border-box;

    .liveVideo-title {
        width           : 517px;
        height          : 60px;
        background-color: rgba(255, 255, 255, 0.3);
        font-size       : 40px;
        font-weight     : bold;
        line-height     : 60px;
        text-align      : center;
    }

    .liveVideo-content {
        height: 380px;
    }
}