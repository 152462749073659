.legend-container {
    position: absolute;
    right: 20px;
    bottom: 110px;
    color: #fff;
    font-family: "微软雅黑";
    font-size: 16px;

    .legend-content {
        display: flex;
        margin-bottom: 10px;

        .legend-icon {
            width: 28px;
            height: 16px;
            border-radius: 5px;
            margin: 3px 5px 0 0;
            background-color: #3680F8;
        }

        p {
            margin: 0;
        }

    }
}