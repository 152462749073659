body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #102334;
}

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

html::-webkit-scrollbar {
  display: none;
}

#root {
  width: 1920px;
  height: 1080px;
  transform-origin: 0% 0%;
}
