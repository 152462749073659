.environment-container {
    width           : 1920px;
    height          : 1080px;
    background-color: #102334;
    font-family     : '微软雅黑';


    .environment-body {

        display   : flex;
        padding   : 0 65px;
        box-sizing: border-box;
        height    : 980px;

        &.ipad-environment-body {
            margin-top: 50px;
        }

        .environment-left {
            background-color: #102334;
            width           : 700px;

            .detailsLineChart-container {
                margin-left: 5px;
                margin-top : 60px;
            }
        }

        .environment-middle {
            background-color: #102334;
            width           : 390px;

            .detailsInfoCard-container {
                margin-left: 28px;
                margin-top : 67px;
            }
        }

        .environment-right {
            background-color: #102334;
            width           : 700px;

            .detailsLineChart-container {
                margin-left: 5px;
                margin-top : 60px;
            }
        }
    }
}