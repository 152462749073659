.equipment-container {
    width: 1920px;
    height: 1080px;
    background-color: #102334;
    font-family: '微软雅黑';
    

    //loading开始
    .loading-content {
        width: 1920px;
        height: 980px;
        display: flex;
        position: relative;

        .loading-container {
            margin: auto auto;
            height: 100px;
            width: 100px;
            background-image: url('../../assets/images/subPage/loading.png');
            background-size: cover;
            background-position: center;
            animation: rotating 1.2s linear infinite
        }
    }

    .isShowLoading {
        display: none;
    }

    @keyframes rotating {
        from {
            transform: rotate(0)
        }

        to {
            transform: rotate(360deg)
        }
    }

    //loading结束

    .equipment-body {
        display: flex;
        background-color: #102334;
        padding-left: 65px;
        padding-right: 65px;
        box-sizing: border-box;
        width: 1920px;
        height: 895px;

        .equipment-left,
        .equipment-right {
            background-color: #102334;
            width: 415px;
            height: 895px;

            .equ-left-content {
                width: 390px;
                height: 850px;
                color: #fff;
                border: 10px solid #000;
                /* 不设置border有可能显示不出边框图片 */
                /* 设置边框图片的各种属性 */
                border-image-source: url('../../assets/images/subPage/border.png');
                /* 边框图片的路径 */
                border-image-slice: 10;
                /* 边框图片的裁剪(裁剪出四个角),不需要单位 */
                border-image-width: 10px;
                border-image-repeat: round;
                /* 平铺方式 repeat:平铺可能显示不完整 round:平铺但会自动调整 stretch:拉伸 */
                box-sizing: border-box;
                margin-top: 20px;
                position: relative;

                .equ-left-content-two {
                    margin: 80px 0;
                }

                .detailsInfoCard-container {
                    width: 375px;
                    height: 200px;
                    color: #fff;
                    border: 0px solid #000;
                    margin-top: 30px;
                    margin-bottom: 30px;

                    .detailsInfoCard-title {
                        margin-bottom: 40px;
                    }

                    .detailsInfoCard-content {
                        margin-left: 30px;

                        .detailsInfoCard-span {
                            margin-right: 25px;
                        }

                        span {
                            margin-right: 0px;

                            .time {
                                font-size: 30px;
                                color: #fff;
                                margin-left: 10px;
                                margin-right: 10px;
                            }
                        }
                    }
                }

                &::before {
                    content: "";
                    width: 383px;
                    border-bottom: 2px solid #098ab5;
                    position: absolute;
                    top: 274px;
                    left: -6px;
                }

                &::after {
                    content: "";
                    width: 383px;
                    border-bottom: 2px solid #098ab5;
                    position: absolute;
                    top: 551px;
                    left: -6px;
                }
            }
        }

        .equipment-middle {
            background-color: #102334;
            width: 985px;
            height: 895px;

            .detailsHeapBarChart-container {
                margin-top: 15px;
            }

            .throughStandby-icon {
                display: inline-block;
                position: absolute;
            }

            // .standby-title {
            //     margin-left: 40px;
            // }

            .equ-middle-bottom {
                display: flex;
                margin-top: 50px;

                .detailsLineChart-container {
                    margin-right: 20px;
                }
            }
        }

        .equipment-right {
            width: 390px;

            .equ-left-content {
                margin-left: 0px;
            }
        }
    }

    .isShowBody {
        display: none;
    }
}