.homecard-container {
    position: relative;
    width   : 25%;

    .wrap1 {
        width   : 400px;
        height  : 460px;
        border  : #3680f8 solid 3px;
        position: absolute;
        top     : 10px;
        left    : 0px;
        z-index : 99;

        .title {
            height          : 80px;
            font-size       : 45px;
            font-weight     : bold;
            font-family     : "微软雅黑";
            color           : #fff;
            background-color: rgba(56, 70, 100, 0.7);
            display         : flex;
            flex-direction  : row;
            justify-content : space-between;
            align-items     : center;
            padding         : 0 30px;

            .title-image {
                width              : 45px;
                height             : 45px;
                background-size    : contain;
                background-repeat  : no-repeat;
                background-position: center;
            }

            .title-more {
                width              : 45px;
                height             : 45px;
                background-image   : url('../../assets/images/slh.png');
                background-color   : transparent;
                background-size    : contain;
                background-repeat  : no-repeat;
                background-position: center;
            }
        }

    }

    .pointer {
        cursor: pointer;
    }

    .wrap2 {
        width   : 400px;
        height  : 460px;
        border  : #3680f8 solid 2px;
        position: absolute;
        opacity : 0.7;
        top     : 15px;
        left    : 6px;
    }

    .wrap3 {
        width   : 400px;
        height  : 460px;
        border  : #3680f8 solid 1px;
        position: absolute;
        opacity : 0.4;
        top     : 20px;
        left    : 12px;
    }

    .wrap4 {
        width   : 400px;
        height  : 460px;
        border  : #3680f8 solid 1px;
        opacity : 0.3;
        position: absolute;
        top     : 25px;
        left    : 18px;
    }
}

a {
    text-decoration: none;
}