.homeqr-container {
    color: #fff;
    font-size: 20px;
    font-family: "微软雅黑";

    .icon {
        margin-bottom: 25px;

        .icon-image {
            width: 150px;
            height: 150px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-right: 30px;
        }

        .icon-title {
            margin-left: 19px;
        }
    }
}