.live-container {
  width: 1920px;
  height: 1080px;
  background-color: #102334;
  font-family: "微软雅黑";

  .detailsHeader-container {
    padding-left: 0;
    padding-right: 0;
  }

  .live-body {
    width: 1920px;
    height: 980px;
    background-color: #102334;
    display: flex;

    &.ipad-live-body {
      margin-top: 40px;
    }

    .live-left {
      width: 675px;
      height: 980px;
      background-color: #102334;
    }

    .live-middle {
      width: 570px;
      height: 980px;
      background-color: #102334;
    }

    .live-right {
      width: 675px;
      height: 980px;
      background-color: #102334;
    }
  }
}
