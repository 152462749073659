.homeflipper-container {
    width: 50%;
    color: #fff;
    font-family: "微软雅黑";

    .homeflipper-title {
        font-size: 20px;
    }

    .homeflipper-data {
        font-size: 50px;
        font-weight: bold;
        margin-right: 15px;
    }

    .homeflipper-unit {
        font-size: 20px;
        font-weight: bold;
        color: #bfbfbfbf;
    }
}