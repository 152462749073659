.detailsHeaderTab-container {
    height          : 85px;
    background-color: #102334;
    padding-left    : 65px;
    padding-right   : 65px;
    box-sizing      : border-box;
    border-bottom   : rgba(255, 255, 255, 0.1) solid 2px;
    position        : relative;

    &.ipad-tab {
        height: 140px;

        .detailsHeaderTab-content {
            line-height: 140px;
            font-size  : 40px;

            .choice {
                font-size: 50px;
            }
        }


    }

    .detailsHeaderTab-content {
        font-size  : 27px;
        color      : #fff;
        line-height: 85px;

        span {
            margin-right: 50px;
            cursor      : pointer;
        }

        .choice {
            font-size  : 28px;
            font-weight: bold;
            color      : #006EFF;
        }
    }

    .detailsHeaderTab-export {
        position: absolute;
        right   : 65px;
        bottom  : 20px;

        .detailsHeaderTab-icon {
            width              : 30px;
            height             : 30px;
            background-image   : url('../../assets/images/subPage/export.png');
            background-color   : transparent;
            background-size    : contain;
            background-repeat  : no-repeat;
            background-position: center;
            position           : absolute;
            right              : 60px;
            bottom             : 2px;
        }

        .detailsHeaderTab-title {
            font-size: 27px;
            color    : #fff;
        }
    }
}

.isShow {
    display: none;
}