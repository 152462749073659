.homefooter-container {
    width           : 100%;
    height          : 500px;
    background-color: #102334;
    padding         : 0 65px;
    box-sizing      : border-box;

    .messaga-box {
        padding-top: 50px;
        display    : flex;

        .homecard-container {
            position   : relative;
            width      : 100%;
            margin-left: 20px;

            .wrap {
                height: 420px;
            }

            .wrap1 {
                width: 1590px;
                @extend .wrap;
                border  : #3680f8 solid 3px;
                position: absolute;
                top     : 0px;
                left    : 0px;

                .title {
                    height          : 80px;
                    font-size       : 50px;
                    font-weight     : bold;
                    font-family     : "微软雅黑";
                    color           : #fff;
                    background-color: rgba(56, 70, 100, 0.7);
                    display         : flex;
                    flex-direction  : row;
                    justify-content : center;
                    align-items     : center;
                    padding         : 0 30px;

                    .title-image {
                        width              : 60px;
                        height             : 60px;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        background-position: center;
                    }

                    .title-more {
                        display            : none;
                        width              : 45px;
                        height             : 45px;
                        background-image   : url('../../assets/images/slh.png');
                        background-color   : transparent;
                        background-size    : contain;
                        background-repeat  : no-repeat;
                        background-position: center;
                    }
                }

            }

            .wrap2 {
                width: 1590px;
                @extend .wrap;

                border  : #3680f8 solid 2px;
                position: absolute;
                opacity : 0.7;
                top     : 5px;
                left    : 6px;
            }

            .wrap3 {
                width: 1590px;
                @extend .wrap;

                border  : #3680f8 solid 1px;
                position: absolute;
                opacity : 0.4;
                top     : 10px;
                left    : 12px;
            }

            .wrap4 {
                width: 1590px;
                @extend .wrap;
                border  : #3680f8 solid 1px;
                opacity : 0.3;
                position: absolute;
                top     : 15px;
                left    : 18px;
            }
        }
    }
}

.statecard-wrap {
    display    : flex;
    padding-top: 30px;

    a {
        color          : #ffe58f;
        text-decoration: none;
    }
}

.statecard-picture {
    width              : 100%;
    height             : 210px;
    background-image   : url('../../assets/images/3dsb.png');
    background-position: -10px -670px;
    background-repeat  : no-repeat;
}

.homefooter-container.ipad {
    .wrap {
        height: 500px !important;
    }

    .statecard-picture {
        margin-top: 30px;
    }

    .statecard-wrap {
        padding-top: 50px;

    }
}

.live-btn {
    height         : 96px;
    width          : 180px;
    margin-left    : 20px;
    font-size      : 30px;
    display        : flex;
    justify-content: center;
    align-items    : center;
    color          : #ffe58f;
    border         : rgba(44, 243, 124, 0.5) solid 1px;
    cursor         : pointer;

    .live-btn-content {
        height: 96px;
        width: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .live-btn-icon {
        height          : 30px;
        width           : 30px;
        background-image: url('../../assets/images/video.png');
        background-size : contain;
        opacity         : 0.6;
        margin-right    : 10px;
    }
}