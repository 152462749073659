.liveTesting-container {
  width: 650px;
  height: 940px;
  margin-top: 20px;
  margin-left: 20px;
  background-color: rgba(0, 0, 3, 0.17);
  color: #fff;
  border: 5px solid #fff;
  box-sizing: border-box;
  overflow: hidden;

  .liveTesting-title {
    width: 640px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 40px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
  }

  .liveTesting-content {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .liveTesting-content-left {
      font-size: 30px;
      position: relative;

      .liveTesting-left-title {
        margin-top: 25px;
        margin-left: 25px;

        span {
          color: #05d380;
          font-size: 45px;
          font-weight: bold;
          margin-left: 20px;
        }

        .liveTesting-position {
          color: #fff;
          font-size: 25px;
        }

        .liveTesting-success {
          // position: absolute;
          // bottom: 0;
          // right: 90px;
          color: #05d380;
          font-size: 80px;
        }

        .liveTesting-error {
          position: absolute;
          bottom: 0;
          right: 90px;
          color: #d81e06;
          font-size: 80px;
        }
      }
    }

    .liveTesting-content-right {
      .liveTesting-image {
        width: 640px;
        height: 570px;
        background-color: transparent;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
}
