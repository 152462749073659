.quality-container {
    width: 1920px;
    height: 1080px;
    background-color: #102334;
    font-family: '微软雅黑';
    

    //loading开始
    .loading-content {
        width: 1920px;
        height: 980px;
        display: flex;
        position: relative;

        .loading-container {
            margin: auto auto;
            height: 100px;
            width: 100px;
            background-image: url('../../assets/images/subPage/loading.png');
            background-size: cover;
            background-position: center;
            animation: rotating 1.2s linear infinite
        }
    }

    .isShowLoading {
        display: none;
    }

    @keyframes rotating {
        from {
            transform: rotate(0)
        }

        to {
            transform: rotate(360deg)
        }
    }

    //loading结束

    .quality-body {
        display: flex;
        background-color: #102334;
        padding-left: 65px;
        padding-right: 65px;
        box-sizing: border-box;

        .quality-left {
            width: 550px;
            height: 895px;
            background-color: #102334;

            .detailsInfoCard-container {
                width: 450px;
                height: 180px;
                margin-top: 30px;

                .detailsInfoCard-content {
                    margin-left: 120px;
                }
            }

            .defectCard {
                color: #fff;
                position: relative;

                .detailsInfoCard-container {
                    height: 410px;
                }

                .defect-content {
                    position: absolute;
                    font-size: 22px;
                    top: 170px;
                    left: 3px;
                    width: 445px;
                    border-top: #098ab5 solid 2px;
                    padding-left: 20px;
                    box-sizing: border-box;

                    div {
                        margin-top: 5px;

                        p {
                            display: inline-block;
                            width: 155px;
                            margin: 0;
                        }

                        span {
                            margin: 0 20px;
                            font-size: 24px;
                            font-weight: bold;
                            display: inline-block;
                            width: 60px;
                        }

                        .defect-bad {
                            color:#FC9800;
                        }
                    }
                }
            }
        }

        .quality-right {
            width: 1370px;
            height: 895px;
            background-color: #102334;

            .detailsLineChart-container {
                margin-top: 30px;
                margin-left: 150px;
            }
        }
    }

    .isShowBody {
        display: none;
    }
}