.liveQuality-container {
  width: 609px;
  height: 940px;
  margin-top: 20px;
  margin-left: 5px;
  background-color: rgba(0, 0, 3, 0.17);
  color: #fff;
  border: 5px solid #fff;
  box-sizing: border-box;

  .liveQuality-title {
    width: 600px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 40px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
  }

  .liveQuality-content {
    margin-left: 5px;

    // 选项卡开始
    .liveQuality-detailsHeaderTab-container {
      width: 595px;
      height: 85px;
      // padding-left: 65px;
      // padding-right: 65px;
      box-sizing: border-box;
      // border-bottom: #A3A3A3 solid 3px;
      position: relative;

      &::after {
        content: "";
        width: 595px;
        border-bottom: 2px solid #098ab5;
        position: absolute;
        top: 80px;
        left: -5px;
      }

      .liveQuality-detailsHeaderTab-content {
        font-size: 24px;
        color: #fff;
        line-height: 85px;

        span {
          margin-right: 30px;
          cursor: pointer;
        }

        .liveQuality-year {
          position: relative;
          z-index: 10;

          .liveQuality-more {
            width: 40px;
            height: 40px;
            background-image: url("../../assets/images/subPage/xiala.png");
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            display: inline-block;
            top: 0px;
            left: 110px;
          }

          .liveQuality-more2 {
            left: 135px;
          }

          .liveQuality-more3 {
            left: 40px;
          }

          &:hover .liveQuality-yearList {
            transition: all 300ms;
            height: 350px;
          }

          .liveQuality-yearList {
            position: absolute;
            top: 30px;
            left: 0;
            background-color: #102334;
            width: 180px;
            height: 0;
            overflow: hidden;
            transition: all 300ms;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

            span {
              display: block;
              color: #fff;
              font-weight: normal;
              font-size: 24px;
            }
          }
        }

        .choice {
          font-size: 25px;
          font-weight: bold;
          color: #006eff;
        }
      }

      .liveQuality-detailsHeaderTab-export {
        position: absolute;
        right: 10px;
        bottom: 27px;

        .liveQuality-detailsHeaderTab-icon {
          width: 27px;
          height: 27px;
          background-image: url("../../assets/images/subPage/export.png");
          background-color: transparent;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          right: 55px;
          bottom: 2px;
        }

        .liveQuality-detailsHeaderTab-title {
          font-size: 24px;
          color: #fff;
        }
      }
    }

    .isShow {
      display: none;
    }

    // 选项卡结束

    //loading开始
    .loading-content {
      width: 600px;
      height: 870px;
      display: flex;
      position: relative;

      .loading-container {
        margin: auto auto;
        height: 100px;
        width: 100px;
        background-image: url("../../assets/images/loading.png");
        background-size: cover;
        background-position: center;
        animation: rotating 1.2s linear infinite;
      }
    }

    .isShowLoading {
      display: none;
    }

    @keyframes rotating {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(360deg);
      }
    }

    //loading结束

    .liveQuality-show-body {
      .liveQuality-cardTop,
      .liveQuality-cardBottom {
        display: inline-flex;

        .detailsInfoCard-container {
          width: 283px;
          height: 150px;
          margin-top: 20px;

          .detailsInfoCard-content {
            margin-left: 15px;
            font-size: 25px;

            span {
              margin-right: 5px;
              font-size: 70px;
            }
          }
        }

        .detailsInfoCard-container:nth-child(1) {
          margin-right: 20px;
        }
      }

      .liveQuality-charts {
        .detailsLineChart-container {
          margin-top: 40px;
        }
      }
    }

    .isShowBody {
      display: none;
    }
  }
}
