.production-container {
    width: 1920px;
    height: 1080px;
    background-color: #102334;
    font-family: '微软雅黑';
    

    //loading开始
    .loading-content {
        width: 1920px;
        height: 980px;
        display: flex;
        position: relative;

        .loading-container {
            margin: auto auto;
            height: 100px;
            width: 100px;
            background-image: url('../../assets/images/subPage/loading.png');
            background-size: cover;
            background-position: center;
            animation: rotating 1.2s linear infinite
        }
    }

    .isShowLoading {
        display: none;
    }

    @keyframes rotating {
        from {
            transform: rotate(0)
        }

        to {
            transform: rotate(360deg)
        }
    }

    //loading结束

    .production-body {
        display: flex;
        background-color: #102334;
        padding-left: 65px;
        padding-right: 65px;
        box-sizing: border-box;

        .production-left {
            width: 500px;
            height: 895px;
            background-color: #102334;

            .left-productionName {

                .detailsInfoCard-container {
                    margin-top: 30px;
                }

            }

            .detailsInfoCard-container {
                width: 420px;
                height: 165px;
                margin-top: 60px;

                .detailsInfoCard-content {
                    margin-left: 100px;
                }
            }


        }

        .production-right {
            width: 1420px;
            height: 895px;
            background-color: #102334;
            overflow: hidden;

            .detailsBarChart-container {
                margin-top: 30px;
                margin-left: 0px;
            }

            .pro-right-bottom {
                display: flex;

                .detailsLineChart-container {
                    margin-top: 40px;
                    margin-left: 0px;
                }

                .detailsTreeMapChart-container {
                    margin-top: 40px;
                    margin-left: 0px;

                    .detailsTreeMapChart-header {
                        margin-left: 68px;
                    }
                }
            }
        }
    }

    .isShowBody {
        display: none;
    }
}