.liveEquipment-container {
  width: 526px;
  height: 560px;
  margin-top: 20px;
  // margin-left: 40px;
  background-color: rgba(0, 0, 3, 0.17);
  color: #fff;
  border: 5px solid #fff;
  box-sizing: border-box;

  .liveEquipment-title {
    width: 517px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.3);
    font-size: 40px;
    font-weight: bold;
    line-height: 60px;
    text-align: center;
  }

  .liveEquipment-content {
    height: 560px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-evenly;

    .liveEquipment-word {
      font-size: 30px;
      display: flex;
      line-height: 50px;
      margin-top: 55px;
    }

    .liveEquipment-icon {
      width: 50px;
      height: 50px;
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 10px;
    }

    .lv-url1 {
      background-image: url("../../assets/images/subPage/sk1.png");
    }

    .lv-url2 {
      background-image: url("../../assets/images/subPage/sk2.png");
    }

    .lv-url3 {
      background-image: url("../../assets/images/subPage/sk3.png");
    }

    .lv-url4 {
      background-image: url("../../assets/images/subPage/sk4.png");
    }

    .lv-url5 {
      background-image: url("../../assets/images/subPage/sk5.png");
    }

    .lv-url6 {
      background-image: url("../../assets/images/subPage/sk6.png");
    }

    .lv-url7 {
      background-image: url("../../assets/images/subPage/sk7.png");
    }

    .lv-url8 {
      background-image: url("../../assets/images/subPage/sk8.png");
    }

    .lv-url3-err {
      background-image: url("../../assets/images/subPage/sk3err.png");
    }

    .lv-url4-err {
      background-image: url("../../assets/images/subPage/sk4err.png");
    }

    .lv-url5-err {
      background-image: url("../../assets/images/subPage/sk5err.png");
    }

    .lv-url6-err {
      background-image: url("../../assets/images/subPage/sk6err.png");
    }

    .lv-url7-err {
      background-image: url("../../assets/images/subPage/sk7err.png");
    }

    .lv-url8-err {
      background-image: url("../../assets/images/subPage/sk8err.png");
    }
  }
}
