.detailsHeader-container {
  position: relative;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding-left: 65px;
  padding-right: 65px;
  box-sizing: border-box;
  line-height: 100px;

  &.ipad-head {
    height: 140px;
    line-height: 140px;

    .detailsHeader-title {
      font-size: 55px;
    }

    .detailsHeader-icon {
      top: 50px;
    }
  }

  .detailsHeader-icon {
    position: absolute;
    top: 35px;
    width: 45px;
    height: 45px;
    background-image: url("../../assets/images/subPage/goback.png");
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .detailsHeader-title {
    font-size: 50px;
    text-align: center;
  }

  .detailsHeader-ipadenv {
    position: absolute;
    right: 65px;
    bottom: -120px;

    .env-icon {
      width: 30px;
      height: 30px;
      background-image: url("../../assets/images/subPage/export.png");
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 60px;
      bottom: 55px;
    }

    .env-title {
      font-size: 27px;
    }
  }

  .detailsHeader-env {
    position: absolute;
    right: 65px;
    bottom: -50px;
    line-height: 29px;

    .env-icon {
      width: 30px;
      height: 30px;
      background-image: url("../../assets/images/subPage/export.png");
      background-color: transparent;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      right: 60px;
      bottom: 0px;
    }

    .env-title {
      font-size: 27px;
    }
  }

  .detailsHeader-noenv {
    display: none;
  }
}
