.detailsTreeMapChart-container {

    .detailsTreeMapChart-header {
        display: flex;

        .detailsTreeMapChart-icon {
            width: 35px;
            height: 35px;
            background-image: url('../../../assets/images/subPage/spot.png');
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 3px;
            margin-right: 5px;
        }

        .detailsTreeMapChart-icon2 {
            width: 35px;
            height: 35px;
            background-image: url('../../../assets/images/subPage/spot2.png');
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin-top: 3px;
            margin-right: 5px;
        }
        
        .detailsTreeMapChart-title {
            font-size: 30px;
            color: #fff;
        }
    }
}