.statecard-container {
    color          : #fff;
    width          : 185px;
    height         : 100px;
    border         : rgba(44, 243, 124, 0.5) solid 1px;
    margin-left    : 50px;
    // padding-top: 10px;
    // padding-left: 10px;
    box-sizing     : border-box;
    font-family    : "微软雅黑";
    overflow       : hidden;

    .statecard-content {
        width       : 200px;
        height      : 100px;
        padding-top : 10px;
        padding-left: 10px;

        .statecard-title {
            font-size     : 28px;
            font-weight   : bold;
            color         : #FFE58F;
            // margin-top: 10px;
            margin-bottom : 10px;
            // margin-left: 10px;
        }

        .statecard-unit {
            font-size     : 20px;
            font-weight   : bold;
            color         : #bfbfbf;
            // margin-left: 10px;
        }

        .statecard-data {
            font-size  : 20px;
            font-weight: bold;
            color      : #3ECD99;
            margin-left: 45px;
        }

        .error {
            color: red;
        }

    }
}