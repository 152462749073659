.homeheader-container {
    width          : 100%;
    height         : 85px;
    color          : #fff;
    font-family    : "微软雅黑";
    box-sizing     : border-box;
    display        : flex;
    justify-content: center;
    align-items    : center;



    .title {
        font-size: 50px;
        position : relative;


    }

    .clock {
        position : absolute;
        font-size: 18px;
        right    : 60px;
        display  : flex;

        span {
            font-size: 30px
        }

        .clock-image {
            width              : 38px;
            height             : 38px;
            background-image   : url('../../assets/images/clock.png');
            background-size    : contain;
            background-repeat  : no-repeat;
            background-position: center;
            margin-right       : 5px;
        }
    }


}


.ipad {
    height    : 100px;
    margin-top: 20px;

    .title {
        font-size  : 60px;
        line-height: 120px;
    }
}