.homebody-container {
    width           : 100%;
    height          : 495px;
    background-color: #102334;
    padding-left    : 65px;
    box-sizing      : border-box;
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
}

.cardMain-container {
    display: flex;
    padding: 10px 30px 0;
}

.cardMain-title {
    font-size   : 18px;
    color       : #fff;
    padding-top : 15px;
    padding-left: 30px;

    .cardMain-icon {
        float              : left;
        width              : 25px;
        height             : 25px;
        background-image   : url('../../assets/images/dian.png');
        background-color   : transparent;
        background-size    : contain;
        background-repeat  : no-repeat;
        background-position: center;
    }

    .cardMain-word {
        margin-left: 5px;
    }
}

.ipad {
    margin-bottom: 40px;
}